import {Link} from "react-router-dom";

export function Products() {
    return(
        <section id="products" className="bg-gray-light mb-lg-n5">
            <div className="container">
                <div className="row justify-content-center pb-3">
                    <div className="col-lg-10 pb-lg-4 text-center">
                        <h3 className="font-alt font-w-600 letter-spacing-2 text-uppercase title-xs-small title-extra-large-2">Prolific Open Digital Platform</h3>
                        <p className="font-alt mb-0 mt-3 text-xs-large text-uppercase title-medium">The foundational platform for digital transformation</p>

                        <p className="font-alt mb-0 mt-3 text-xs-large">Engage business and commercial customers throughout their digital engagement journey.</p>

                        <img src="assets/images/pod.png" width="750" alt="" className="logo-text-light mt-2"/>

                        <p className="font-alt mb-0 mt-3 text-xs-large">
                            Prolific Banking developed the ProlificOpen Digital platform to support bank digital engagement
                            with business and commercial customers. Bringing a unique and unprecedent approach to commercial
                            onboarding, automated service provisioning and compliance, delivered through various platform modules
                        </p>

                        <div className="text-center mt-4">
                            <Link className="page-scroll btn btn-base-color btn-shadow mt-1" to="/pod" target="page-top">Read More</Link>
                        </div>

                    </div>
                </div>

                <div className="row justify-content-center pb-2">
                    <div className="col-lg-9 pb-lg-4 text-center">
                        <span className="bg-base-color d-inline-block sep-line-thick-long"></span>
                        <p className="font-alt mb-0 mt-3 text-xs-large text-uppercase title-medium">Platform Modules</p>
                    </div>
                </div>


                {/* On-Ramp - On-Record - Fi-Streams */}
                <div className="row">

                    <div className="col-md-4">
                        <div className="features-block text-center">
                            {/*<img src="assets/images/on-ramp.png" alt="" className="logo-text-light"/>*/}
                            {/*<div className="block-icon ease">*/}
                            {/*<i className="fa fa-lightbulb-o ease"></i>*/}
                            {/*</div>*/}


                            <span className="d-block font-alt letter-spacing-2 mt-4 text-uppercase title-medium">On-Ramp</span>
                            <span className="bg-base-color d-inline-block mt-2 sep-line"></span>
                            <p className="mt-2 text-large">Digital Enrollment for Business Customers</p>
                            <p className="text-left  text-medium">
                                <ul>
                                    <li>Replace paper-based service applications and automate manual enrollment processes</li>
                                    <li>End-to-End solution including service provisioning integrated with core systems, CRM, online banking platforms and other digital surrounds</li>
                                    <li>Enable customer self-service with secured compliant onboarding</li>
                                    <li>Zero code tools for creating and modifying forms, application and workflows</li>
                                    <li>Included Digital Signature or integrated Digital Signature support for customer signatures</li>
                                    <li>Multi-department workflows for task review and approval</li>
                                    <li>Integrated secure messaging for internal communication and external client correspondence</li>
                                </ul>
                            </p>

                        </div>

                    </div>

                    <div className="col-md-4 mt-5 mt-md-0">
                        <div className="features-block text-center">
                            {/*<img src="assets/images/on_record_logo.png" alt="" className="logo-text-light"/>*/}
                            {/*<div className="block-icon ease">*/}
                            {/*    <i className="fa fa-heart-o ease"></i>*/}
                            {/*</div>*/}


                            <span className="d-block font-alt letter-spacing-2 mt-4 text-uppercase title-medium">On-Record</span>
                            <span className="bg-base-color d-inline-block mt-2 sep-line"></span>
                            <p className="mt-2 text-large">Automated Customer Compliance</p>
                            <p className="text-left text-medium">
                                <ul>
                                    <li>ACH, Wire, and Remote Deposit risk and limit reviews</li>
                                    <li>Credit/Exposure, Risk and Exception Approvals</li>
                                    <li>Customer agreement renewals and Internal Compliance/Approval Tasks</li>
                                    <li>Scheduled, recurring or on-demand tasks</li>
                                    <li>Automatic requests for information and presentation of agreements for digital signature</li>
                                    <li>Multi-department workflows for review and approval</li>
                                    <li>Audit record of each customer interaction</li>
                                    <li>SLA monitoring, reporting and alerts for each risk, audit and compliance task</li>
                                </ul>
                            </p>
                        </div>

                    </div>

                    <div className="col-md-4 mt-5 mt-md-0">
                        <div className="features-block text-center">
                            {/*<img src="assets/images/on_record_logo.png" alt="" className="logo-text-light"/>*/}
                            {/*<div className="block-icon ease">*/}
                            {/*    <i className="fa fa-heart-o ease"></i>*/}
                            {/*</div>*/}


                            <span className="d-block font-alt letter-spacing-2 mt-4 text-uppercase title-medium">Fi-Streams</span>

                            <span className="bg-base-color badge badge-secondary">Coming Soon</span>

                            {/*<span className="bg-base-color d-inline-block mt-2 sep-line"></span>*/}
                            <p className="mt-2 text-large">Digital Data Exchange</p>
                            <p className="text-left text-medium">
                                <ul>
                                    <li>API based (inbound or outbound) to enable bank customers and FinTech partners seamless integration for large corporate workflows and/or extending financial reporting capabilities </li>
                                    <li>Cloud Storage Integration for secure and direct transfer to business and corporate environments without requiring traditional file transmission configuration</li>
                                    <li>Web and tablet-based exchanges with support for traditional file transfer methods such as SFTP</li>
                                    <li>Transform between standard and non-standard message formats with zero-code adjustments of standard formats for customer specific requests </li>
                                </ul>
                            </p>
                        </div>

                    </div>

                </div>

                <div className="row">

                    <div className="col-md-4 mt-5 mt-md-0">
                        <div className="text-center">
                            <Link className="page-scroll btn btn-base-color btn-shadow mt-1" to="/onramp" target="page-top">Read More</Link>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="text-center">
                            <Link className="page-scroll btn btn-base-color btn-shadow mt-1" to="/onrecord" target="page-top">Read More</Link>
                        </div>

                    </div>

                    <div className="col-md-4 mt-5 mt-md-0">
                        <div className="text-center">
                            <Link className="page-scroll btn btn-base-color btn-shadow mt-1" to="/fistreams" target="page-top">Read More</Link>
                        </div>

                    </div>

                </div>

                {/* On-Boarder - On-Track - Payment Piplelines */}

                {/*<div className="row mt-5">*/}

                {/*    <div className="col-md-4 mt-5 mt-md-0">*/}
                {/*        <div className="features-block text-center">*/}
                {/*            /!*<img src="assets/images/on_boarder_logo.png" alt="" className="logo-text-light"/>*!/*/}

                {/*            /!*<div className="block-icon ease">*!/*/}
                {/*            /!*    <i className="fa fa-flask ease"></i>*!/*/}
                {/*            /!*</div>*!/*/}


                {/*            <span*/}
                {/*                className="d-block font-alt letter-spacing-2 mt-4 text-uppercase title-medium">On-Boarder</span>*/}
                {/*            <span className="bg-base-color d-inline-block mt-2 sep-line"></span>*/}
                {/*            <p className="mt-2 text-large">Business and Commercial Account Opening</p>*/}
                {/*            <p className="text-left text-medium">*/}
                {/*                <ul>*/}
                {/*                    <li>Engage across file, web and API engagement channels</li>*/}
                {/*                    <li>Integrate and automate client account opening</li>*/}
                {/*                    <li>Perform KYC verification with third-party data / analytics companies </li>*/}
                {/*                    <li>Automatically and securely deliver client data to Bank applications </li>*/}
                {/*                    <li>Support digital product fulfillment</li>*/}
                {/*                </ul>*/}
                {/*            </p>*/}

                {/*        </div>*/}

                {/*    </div>*/}

                {/*    <div className="col-md-4">*/}
                {/*        <div className="features-block text-center">*/}
                {/*            /!*<img src="assets/images/on-ramp.png" alt="" className="logo-text-light"/>*!/*/}
                {/*            /!*<div className="block-icon ease">*!/*/}
                {/*                /!*<i className="fa fa-lightbulb-o ease"></i>*!/*/}
                {/*            /!*</div>*!/*/}


                {/*            <span className="d-block font-alt letter-spacing-2 mt-4 text-uppercase title-medium">On-Track</span>*/}
                {/*            <span className="bg-base-color d-inline-block mt-2 sep-line"></span>*/}
                {/*            <p className="mt-2 text-large">Digital Enrollment for Business Customers</p>*/}
                {/*            <p className="text-left  text-medium">*/}
                {/*                <ul>*/}
                {/*                    <li>Replace paper-based service applications</li>*/}
                {/*                    <li>Automate manual enrollment processes</li>*/}
                {/*                    <li>End-to-End solution including service provisioning</li>*/}
                {/*                    <li>Enable customer self-service</li>*/}
                {/*                    <li>Secured Compliant Onboarding </li>*/}
                {/*                </ul>*/}
                {/*            </p>*/}

                {/*        </div>*/}

                {/*    </div>*/}

                {/*    <div className="col-md-4 mt-5 mt-md-0">*/}
                {/*        <div className="features-block text-center">*/}
                {/*            /!*<img src="assets/images/on_record_logo.png" alt="" className="logo-text-light"/>*!/*/}
                {/*            /!*<div className="block-icon ease">*!/*/}
                {/*            /!*    <i className="fa fa-heart-o ease"></i>*!/*/}
                {/*            /!*</div>*!/*/}


                {/*            <span className="d-block font-alt letter-spacing-2 mt-4 text-uppercase title-medium">Payment Pipelines</span>*/}
                {/*            <span className="bg-base-color d-inline-block mt-2 sep-line"></span>*/}
                {/*            <p className="mt-2 text-large">Automated Customer Compliance</p>*/}
                {/*            <p className="text-left text-medium">*/}
                {/*                <ul>*/}
                {/*                    <li>Scheduled, recurring or on-demand tasks</li>*/}
                {/*                    <li>Automatic requests for information and presentation of agreements for digital signature </li>*/}
                {/*                    <li>Multi-department workflow for review and approval</li>*/}
                {/*                    <li>Audit record of each customer interaction</li>*/}
                {/*                    <li>SLA monitoring, reporting and alerts for each risk, audit and compliance task</li>*/}
                {/*                </ul>*/}
                {/*            </p>*/}
                {/*        </div>*/}

                {/*    </div>*/}

                {/*</div>*/}

                {/*<div className="row">*/}

                {/*    <div className="col-md-4 mt-5 mt-md-0">*/}
                {/*        <div className="text-center">*/}
                {/*            <a href="#fun-facts" className="page-scroll btn btn-base-color btn-shadow mt-1">Read*/}
                {/*                More</a>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className="col-md-4">*/}
                {/*        <div className="text-center">*/}
                {/*            <a href="#fun-facts" className="page-scroll btn btn-base-color btn-shadow mt-1">Read*/}
                {/*                More</a>*/}
                {/*        </div>*/}

                {/*    </div>*/}

                {/*    <div className="col-md-4 mt-5 mt-md-0">*/}
                {/*        <div className="text-center">*/}
                {/*            <a href="#fun-facts" className="page-scroll btn btn-base-color btn-shadow mt-1">Read*/}
                {/*                More</a>*/}
                {/*        </div>*/}

                {/*    </div>*/}

                {/*</div>*/}

            </div>

        </section>
    )
}
