import {Link} from "react-router-dom";

export function Pod() {

    return (
        <div>

            <section id="pod" className="bg-cover bg-gray bg-overlay-black-6 h-100 w-100 mt-auto">
                <div className="container h-100">
                    <div className="row align-items-center h-100 justify-content-center">
                        <div className="col-lg-10 pt-3 text-center">
                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-uppercase text-white title-xs-extra-large title-extra-large-2">
                                Prolific Open Digital Platform
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">
                    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                    <p className="font-alt mb-0 mt-2 text-xs-large text-uppercase title-medium">
                        Commercial Digital Banking Platform
                    </p>

                    <p className="font-alt mb-0 mt-3 text-xs-large">
                        Prolific Open Digital is a purpose-built digital banking platform designed by commercial banking industry
                        veterans and software development professionals committed to providing open and innovative commercial solutions
                        for financial institutions and their customers.
                    </p>

                    <p className="font-alt mb-0 mt-3 text-xs-large">All <span className="font-weight-bold"> Prolific Open Digital </span>modules leverage many of the platform features, including but not limited to:</p>

                    <ul>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Business and commercial banking entitlements with service specific limits, approvals, and audit.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Role based solution secured with Multi-Factor Authentication and Open Authentication Support. </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Dynamic and flexible “no-code” platform tools enable evolving engagement models as your bank grows.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Cloud native platform enables elasticity, performance at scale, modern security, and high availability.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Foundational internal and external APIs provide embedded banking capabilities and support bank technology team direct integrations.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Integrations with Core, CRM, Digital Banking, Payments, and other Third-Party Provider applications.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Fundamental engagement with a modern UI, secure real-time messaging, and digital signatures enables bank customers to respond to and engage with the bank from any device [mobile,tablet, desktop] without having to download sensitive data to a local insecure storage device.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">SLA Monitoring and Detailed Status Reporting.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Subscription based notifications enable organizations to stay up to date and receive reminders on each of their assigned risk, audit, compliance, or other tasks.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Track, monitor and manage data and transactions with a dashboard overview and detailed drill down capability which provides end-to-end tracing for all platform activities.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Securely connect to customer or partner systems or delivery endpoints with a simplified UI.</li>
                    </ul>

                </div>
            </div>

            <div className="row justify-content-center pb-2 mt-5 mb-5">
                <div className="col-lg-9 pb-lg-4 text-center">
                    <span className="bg-base-color d-inline-block sep-line-thick-long"></span>
                    <p className="font-alt mb-0 mt-3 text-xs-large text-uppercase title-medium">Platform Modules</p>
                </div>
            </div>

            <div className="container">
                <div className="row">

                    <div className="col-md-4">
                        <div className="features-block text-center">
                            {/*<img src="assets/images/on-ramp.png" alt="" className="logo-text-light"/>*/}
                            {/*<div className="block-icon ease">*/}
                            {/*<i className="fa fa-lightbulb-o ease"></i>*/}
                            {/*</div>*/}


                            <span className="d-block font-alt letter-spacing-2 mt-4 text-uppercase title-medium">On-Ramp</span>
                            <span className="bg-base-color d-inline-block mt-2 sep-line"></span>
                            <p className="mt-2 text-large">Digital Enrollment for Business Customers</p>
                            <p className="text-left  text-medium">
                                <ul>
                                    <li>Replace paper-based service applications and automate manual enrollment processes</li>
                                    <li>End-to-End solution including service provisioning integrated with core systems, crm, online banking platforms and other digital surrounds</li>
                                    <li>Enable customer self-service with secured compliant onboarding</li>
                                    <li>Zero code tools for creating and modifying forms, application and workflows</li>
                                    <li>Included Digital Signature or integrated Digital Signature support for customer signatures</li>
                                    <li>Multi-department workflow for task review and approval</li>
                                    <li>Integrated secure messaging for internal communication and external client correspondence</li>
                                </ul>
                            </p>

                        </div>

                    </div>

                    <div className="col-md-4 mt-5 mt-md-0">
                        <div className="features-block text-center">
                            {/*<img src="assets/images/on_record_logo.png" alt="" className="logo-text-light"/>*/}
                            {/*<div className="block-icon ease">*/}
                            {/*    <i className="fa fa-heart-o ease"></i>*/}
                            {/*</div>*/}


                            <span className="d-block font-alt letter-spacing-2 mt-4 text-uppercase title-medium">On-Record</span>
                            <span className="bg-base-color d-inline-block mt-2 sep-line"></span>
                            <p className="mt-2 text-large">Automated Customer Compliance</p>
                            <p className="text-left text-medium">
                                <ul>
                                    <li>ACH, Wire, and Remote Deposit risk and limit reviews</li>
                                    <li>Credit/Exposure, Risk and Exception Approvals</li>
                                    <li>Customer agreement renewals and Internal Compliance/Approval Tasks</li>
                                    <li>Scheduled, recurring or on-demand tasks</li>
                                    <li>Automatic requests for information and presentation of agreements for digital signature</li>
                                    <li>Multi-department workflow for review and approval</li>
                                    <li>Audit record of each customer interaction</li>
                                    <li>SLA monitoring, reporting and alerts for each risk, audit and compliance task</li>
                                </ul>
                            </p>
                        </div>

                    </div>

                    <div className="col-md-4 mt-5 mt-md-0">
                        <div className="features-block text-center">
                            {/*<img src="assets/images/on_record_logo.png" alt="" className="logo-text-light"/>*/}
                            {/*<div className="block-icon ease">*/}
                            {/*    <i className="fa fa-heart-o ease"></i>*/}
                            {/*</div>*/}


                            <span className="d-block font-alt letter-spacing-2 mt-4 text-uppercase title-medium">Fi-Streams</span>

                            <span className="bg-base-color badge badge-secondary">Coming Soon</span>

                            {/*<span className="bg-base-color d-inline-block mt-2 sep-line"></span>*/}
                            <p className="mt-2 text-large">Digital Data Exchange</p>
                            <p className="text-left text-medium">
                                <ul>
                                    <li>API based (inbound or outbound) to enable bank customers and FinTech partners seamless integration for large corporate workflows and/or extending financial reporting capabilities </li>
                                    <li>Cloud Storage Integration for secure and direct transfer to business and corporate environments without requiring traditional file transmission configuration</li>
                                    <li>Web and tablet-based exchanges with support for traditional file transfer methods such as SFTP</li>
                                    <li>Transform between standard and non-standard message formats with zero-code adjustments of standard formats for customer specific requests </li>
                                </ul>
                            </p>
                        </div>

                    </div>

                </div>

                <div className="row">

                    <div className="col-md-4 mt-5 mt-md-0">
                        <div className="text-center">
                            <Link className="page-scroll btn btn-base-color btn-shadow mt-1" to="/onramp" target="page-top">Read More</Link>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="text-center">
                            <Link className="page-scroll btn btn-base-color btn-shadow mt-1" to="/onrecord" target="page-top">Read More</Link>
                        </div>

                    </div>

                    <div className="col-md-4 mt-5 mt-md-0">
                        <div className="text-center">
                            <Link className="page-scroll btn btn-base-color btn-shadow mt-1" to="/fistreams" target="page-top">Read More</Link>
                        </div>

                    </div>

                </div>
            </div>


            <div className="container mt-5">
                <div className="row align-items-center border-top"/>
            </div>

        </div>

        )

}