
export function About() {
    return(
        <section id="about" className="bg-white">
            <div className="container">
                <div className="row justify-content-center pb-5">
                    <div className="col-lg-9 pb-lg-4 text-center">
                        <h3 className="font-alt font-w-600 letter-spacing-2 text-uppercase title-xs-small title-extra-large-2">About Prolific</h3>
                        <p className="font-alt mb-0 mt-3 text-xs-large text-uppercase title-medium">Unleash the power of Prolific Banking</p>
                        <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"></span>
                    </div>

                </div>


                <div className="row">
                    <div className="col-lg-6">
                        <div className="carousel-custom" data-pagedots="true" data-draggable="false"
                             data-autoplay="5000">
                            {/*<div className="carousel-cell w-100">*/}
                            {/*    <img src="assets/images/on-ramp-fpc.jpg" alt=""*/}
                            {/*         className="img-fluid box-shadow-shallow rounded"/>*/}
                            {/*</div>*/}

                            <div className="carousel-cell w-100">
                                <img src="assets/images/on-ramp-g-fpc.png" alt=""
                                     className="img-fluid rounded"/>
                            </div>


                            {/*<div className="carousel-cell w-100">*/}
                            {/*    <img src="assets/images/on-record-fpc.jpg" alt=""*/}
                            {/*         className="img-fluid box-shadow-shallow rounded"/>*/}
                            {/*</div>*/}

                            <div className="carousel-cell w-100">
                                <img src="assets/images/on-record-g-fpc.png" alt=""
                                     className="img-fluid rounded"/>
                            </div>


                            <div className="carousel-cell w-100">
                                <img src="assets/images/fi-streams-g-fpc.png" alt=""
                                     className="img-fluid rounded"/>
                            </div>

                        </div>

                    </div>


                    <div className="col-lg-6">
                        <div className="pl-lg-4 pt-5 pt-lg-0">
                            <p className="font-alt text-extra-large text-left">
                                <span className="prolific-blue"> Prolific Banking </span>provides financial institutions a customer centric digital engagement platform which enables transformational customer onboarding, product delivery and servicing solutions for business and commercial customers.
                            </p>
                            <p className="font-alt mt-2 text-extra-large text-left">
                                In a rapidly changing financial market, Prolific Banking is delivering the transformational solutions that help our bank partners compete with other banks and meet the challenge of technology disruption.
                            </p>
                            <p className="font-alt mt-2 text-extra-large text-left">
                                Leveraging extensive business and commercial banking experience, a modern integrated cloud technology stack and an innovative transformational perspective; our platform enables financial institutions to provide the
                                business and commercial services necessary to compete in the evolving marketplace.
                            </p>

                            <a href="#products" className="page-scroll btn btn-base-color btn-shadow mt-1">Read
                                More</a>
                        </div>

                    </div>

                </div>

            </div>

        </section>
    )
}
