import {Link} from "react-router-dom";

export function Footer() {
    return(
        <footer className="bg-white bg-gray-light py-5">
            <div className="container">
                <div className="row align-items-center pb-5">

                    <div className="col-md-4 text-center text-md-left">
                        <img src="assets/images/logo-prolific.png" alt="" className="footer-logo opacity-5"/>
                    </div>

                    <div className="col-md-4 text-center text-md-center">
                        <img src="assets/images/soclogo.jpeg" alt="" className="footer-logo opacity-7"/>
                    </div>

                    <div className="col-md-4">
                        <ul className="footer-icon-social mb-0 mt-4 p-0 text-center text-md-right title-xs-small title-medium">
                            <li className="list-inline-item">
                                <a href="https://www.linkedin.com/company/prolific-banking-inc" target="_blank" className="text-gray-dark"><i className="fa fa-linkedin" /></a>
                            </li>
                        </ul>
                    </div>

                </div>

                <div className="row align-items-center border-top pt-5">
                    <div className="col">
                        <span className="mr-5 font-alt font-w-600 letter-spacing-1 text-gray-dark text-small text-uppercase">&copy; 2022 Prolific Banking. All rights reserved.</span>
                        <span className="ml-5 mr-5 font-alt font-w-600 letter-spacing-1 text-gray-dark text-small text-uppercase">
                            <Link className="text-gray-dark" to="/compliance" target="page-top">Security and Compliance</Link>
                        </span>
                        <span className="ml-5 font-alt font-w-600 text-gray-dark text-small text-uppercase text-align-right">
                            <a href="http://go.pardot.com/l/906392/2021-03-01/296xy" target="_blank" className="text-gray-dark">Subscribe to Blog</a>
                        </span>
                    </div>
                </div>

            </div>

        </footer>
    )
}
