import './App.css';
import "./Header";
import {Footer} from "./Footer";
import {Route, Routes} from "react-router-dom";
import {Main} from "./Main";
import {ProductDetails} from "./ProductDetails";
import {Compliance} from "./Compliance";
import {Blog} from "./Blog";
import {OnRamp} from "./OnRamp";
import {FiStreams} from "./FiStreams";
import {OnRecord} from "./OnRecord";
import {Pod} from "./Pod";
import {CustomerAccess} from "./CustomerAccess";
import {BlogContent} from "./BlogContent";
import {BlogTwo} from "./BlogTwo";

function App() {
  return (
    <div className="App">

        <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/products" element={<ProductDetails />} />
            <Route path="/compliance" element={<Compliance />} />
            <Route path="/blogg" element={<Blog />} />
            <Route path="/bloggg" element={<BlogTwo />} />
            <Route path="/onramp" element={<OnRamp />} />
            <Route path="/onrecord" element={<OnRecord />} />
            <Route path="/fistreams" element={<FiStreams />} />
            <Route path="/pod" element={<Pod />} />
            <Route path="/customeraccess" element={<CustomerAccess />} />
            <Route path="/blogc" element={<BlogContent />} />
        </Routes>

        <Footer />

        <a href="#page-top" className="page-scroll scroll-to-top"><i className="fa fa-angle-up"></i></a>

    </div>
  );
}

export default App;
